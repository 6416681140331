.Toggler{
    border-radius: 0.775rem ;
}

.PhoneText{
    display:none;;
}
.PCText{
    display: block;
}

.Stickybtn{
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    color: black;
    right: 15px;
    bottom: 15px;
    width: 40px;
    height: 40px;
    background: linear-gradient(150deg,#dc4f17 5%,#df1111 20%,#903ec7 100%);
    border-radius: 15px;
    cursor: pointer;
}

.NavlinkListElement{
    font-size: medium;
    transition: all 0.5s ease-out;
    text-decoration: none;
    color: white;
}

.NavlinkListElement:hover{
    color: rgb(185, 10, 92) ;
}

.NavlinkListElement:focus{
    color: rgb(185, 10, 92) ;
}




@media only screen and (max-width : 991.5px){
    .Stickybtn{
        background-color:rgba(87, 0, 128, 0.655);
        width: 35px;
        height: 35px;
    }
    .PCText{
        display: none;
    }
    .PhoneText{
        display: block;
    }
       
    .NavlinkListElement{
        font-size: 1rem;
        border: 1.25px solid white; 
        font-weight: 600;
        color: white;
        padding: 2px 40px;
        border-radius: 20px;
        background: linear-gradient(150deg,#dc4f17 5%,#df1111 20%,#903ec7 100%);
    }

    .NavlinkListElement:focus{
        color: rgb(0, 0, 0) ;
        border: 3px solid white;
        font-weight: 900;
    }
}
.MainDiv{
    display: flex;
    flex-direction: column;
    padding: 5vw;
    width: 100vw;
    min-height: 100vh;
    background-image: linear-gradient( 115.1deg,  #000000 38.2%,  #380557 138.2% );
    background-repeat: no-repeat;
    background-attachment: fixed;
}






@media only screen and (min-width : 800px){
    

}

